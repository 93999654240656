<template>
    <div class="row no-gutters">
      <template v-for="(playground, i) of playgrounds">
        <b-col class="mt-1" :key="'playground-key' + i">
          <div :class="isBadged? 'badge-score-pending' : ''">
            {{ playground.name }}
            {{ i < playgrounds.length - 1 && playgrounds.length > 1 ? ',' : ''  }}
          </div>
        </b-col>
      </template>
    </div>
</template>
<script>
export default {
  props: {
    playgrounds: {
      type: Array,
      default: () => {},
    },
    isBadged: {
      type: Boolean,
      default: true,
    }
  }
}
</script>